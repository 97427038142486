import { http } from 'utils/http';
import { POST, GET, IHtttpOptions, HandlePanic } from 'utils/decorators/http';
import { handleRequest } from 'utils/tool';

class UserApi {
  /**
   * 登录
   * @param params
   * @param option
   * @param handle
   */
  @POST({
    url: '/api/login'
  })
  async login<T = any>(params?: any, option?: IHtttpOptions, handle?: HandlePanic<T>) {
    return await handleRequest<T>(params, handle);
  }

  /**
   * 登出
   * @param params
   * @param option
   * @param handle
   */
  @POST({
    url: '/api/logout'
  })
  async loginOut<T = any>(params?: any, option?: IHtttpOptions, handle?: HandlePanic<T>) {
    return await handleRequest<T>(params, handle);
  }

  /**
   * 获取用户信息
   * @param params
   * @param option
   * @param handle
   * userid
   */
  @GET({
    url: '/api/user/user'
  })
  async getUserInfoByCookie<T = any>(params?: any, option?: IHtttpOptions, handle?: HandlePanic<T>) {
    return await handleRequest<T>(params, handle);
  }

  // 获取facebook平台账号以及选中的账号
  @GET({
    url: '/api/twitter/account'
  })
  async getTwitterAccounts(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // twitter授权验证
  @GET({
    url: '/api/twitter/platform'
  })
  async getTwPlatforms(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }
}

export const userApi = new UserApi();
