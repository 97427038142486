import { action, observable, runInAction } from 'mobx';
import { campaignApi } from 'services/twitter/campaign.service';
import { ICampaignQuery, campaignTableData } from 'interface/twitter/compaignTable.interface';

export class CampaignStore {
  @observable
  public loading: boolean = false;

  @observable
  public campaignData: campaignTableData | null = null;

  @observable
  public query: ICampaignQuery = {
    size: 15,
    page: 1,
    search: ''
  };

  @action.bound
  public setQuery(search: string, page?: number, size?: number) {
    this.query = { search, page: page || this.query.page, size: this.query.size };
    return this.query;
  }

  // 获取campaign 列表数据
  @action.bound
  public async getCampaignList(page?: number, pageSize?: number) {
    this.setQuery(this.query.search, page, pageSize);
    this.loading = true;
    return campaignApi.getCampaigns(this.query).then(res => {
      runInAction(() => {
        this.loading = false;
        this.campaignData = res;
      });
      return Promise.resolve({ success: true, message: '' });
    });
  }

  @action.bound
  public async putStatus(id: number, status: string) {
    return campaignApi.putCampaigns(String(id + '/status'), { data: { status } }).then(res => {
      runInAction(() => {
        this.getCampaignList();
      });
      return Promise.resolve({ success: true, message: '' });
    });
  }
}
export const campaignStore = new CampaignStore();
