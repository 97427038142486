export const HEADER_HEIGHT = '60px';

export const SUB_HEADER_HEIGHT = '50px';

export const BLACK_FONT_COLOR = '#191919';
export const BLACKLIGHT_FONT_COLOR = '#4C4C4C';

export const BLUE_COLOR = '#1a73e8';

export const SUB_BLUE_COLOR = '#4285f4';

export const DISABLE_BORDER_COLOR = '#ddd';

export const DISABLE_FONT_COLOR = '#cdcdcd';

export const FONT_ICON = {
  fontIcon: {
    fontSize: '20px',
    marginRight: '18px',
    color: '#000'
  },
  fontIconRoot: {
    width: 'auto',
    height: 'auto'
  }
};

export const FORMBAR_HEIGHT = '60px';

export const FORM_ITEM_PL = '196px';

export const TEXTFIELD_PADDING = {
  textFieldRoot: {
    '& input': {
      padding: '15.5px 14px'
    }
  }
};

export const TEXTFIELD_SELECT_PADDING = {
  textFieldRootSelect: {
    '& .MuiOutlinedInput-input': {
      padding: '15.5px 24px'
    }
  }
};
