import { action, observable, runInAction } from 'mobx';
import { cardApi } from '../../services/twitter/card.service';
import {
  InewAdText,
  AdTextData,
  IAdTextQuery,
  adTextObj,
} from 'interface/twitter/card.interface';
import {
  creativeData,
  ICreativeQuery,
} from 'interface/twitter/creative.interface';

export class CardStore {
  @observable
  public loading: boolean = false;

  @observable
  public creativeList: creativeData | null = null;

  @observable
  public adTextList: AdTextData = {
    total: 0,
    data: [],
  };

  // 新加文案
  @action.bound
  public async setAdTextData(info: adTextObj) {
    this.adTextList.data.unshift(info);
  }

  // 设置新添文案tweet内容值
  @action.bound
  public async setTwwet(index: number, tweet: string) {
    this.adTextList.data[index].tweet = tweet;
  }

  // 获取文案列表数据
  @action.bound
  public async getAllAdText(
    campaign_id?: number,
    text?: string,
    page?: number,
    getAll?: boolean
  ) {
    const _params: IAdTextQuery = {
      campaign_id,
      page: getAll ? 0 : page || 1,
      size: 5,
      text,
    };
    this.loading = true;
    return cardApi.getAdText(_params).then(res => {
      runInAction(() => {
        this.loading = false;
        this.adTextList = res;
      });
      return Promise.resolve({ success: true, message: '' });
    });
  }

  // 获取creative 列表数据
  @action.bound
  public async getCreativesList(
    campaign_id: number,
    text?: string,
    page?: number
  ) {
    const _params: ICreativeQuery = {
      campaign_id: campaign_id, //compaign id动态传输
      page: page || 1, //当前页数
      page_size: 20, //每页条数
      id_name: text, //搜索查询
    };
    this.loading = true;
    return cardApi.getCreatives(_params).then(res => {
      runInAction(() => {
        this.loading = false;
        this.creativeList = res;
      });
      return Promise.resolve({ success: true, message: '' });
    });
  }

  // 新建文案
  @action.bound
  public async postText(value: InewAdText) {
    return cardApi.postAdText(value).then(res => {
      runInAction(() => {
        this.getAllAdText(value.campaign_id);
      });
      return Promise.resolve({ success: true, message: '' });
    });
  }

  // 文案 删除某一项
  @action.bound
  public async delText(id: number, campaign_id: number) {
    return cardApi.delAdText(String(id)).then(res => {
      runInAction(() => {
        this.getAllAdText(campaign_id);
      });
      return Promise.resolve({ success: true, message: '' });
    });
  }

  // creative 删除某一项
  @action.bound
  public async delCreative(id: number, campaign_id: number) {
    return cardApi.delCreative(String(id)).then(res => {
      runInAction(() => {
        this.getCreativesList(campaign_id);
      });
      return Promise.resolve({ success: true, message: '' });
    });
  }

  // 上传文件
  @action.bound
  public async uploadFile(file: File) {
    const params = new FormData();
    params.append('file', file);

    return cardApi
      .postUploadFile(params)
      .then(res => {
        return Promise.resolve({ success: true, data: res });
      })
      .catch(res => {
        return Promise.reject({ success: false, data: res });
      });
  }

  // 新建 card
  @action.bound
  public async postNewCard(params: {
    campaign_id: number;
    cards: Array<{
      creative_type: string;
      url: string;
    }>;
    text: string;
    action: string;
    name: string;
  }) {
    return cardApi.postNewCard(params).then(res => {
      return Promise.resolve({ success: true, message: '' });
    });
  }
}
export const cardStore = new CardStore();
