import * as React from 'react';
import { Menu as MenuIcon, Settings } from '@material-ui/icons';
import { IconButton, Box, Button, MenuItem, Menu, Divider, Badge } from '@material-ui/core';
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import { HEADER_HEIGHT } from 'constants/styles';
import { contact } from 'assets/ionicons';
import { IUserInfo } from 'interface/user.interface';
import { userStore } from '../../../../store/user.store';
import { forwardTo } from 'utils/tool';
import { hostConfig } from 'config/app.config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      height: HEADER_HEIGHT,
      width: '100%',
      boxShadow: '0px 3px 3px 0px rgba(207,207,207,1)',
      padding: '0 32px 0 23px ',
      position: 'fixed',
      top: 0,
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#fff',
      boxSizing: 'border-box'
    },
    menuBtn: {
      height: '39px',
      width: '39px',
      color: '#000000'
    },
    avatar: {
      height: '100%',
      width: '100%',
      borderRadius: '50%'
    },
    btn: {
      marginRight: '10px'
    },
    name_email: {
      paddingLeft: 16,
      display: 'flex',
      flexDirection: 'column'
    },
    name: {
      width: '166px',
      fontSize: 14,
      color: '#000',
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    email: {
      fontSize: 12,
      color: '#4285f4',
      width: '166px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    }
  })
);

const StyledBadge = withStyles((theme: Theme) => ({
  badge: {
    top: '18%',
    right: '15%',
    backgroundColor: 'rgba(0,0,0,0)'
  }
}))(Badge);

interface Props {
  hanleMenu: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  userInfo: IUserInfo | null;
}

export const LayoutHeader: React.SFC<Props> = ({ hanleMenu, userInfo }) => {
  const classes = useStyles();
  return (
    <header className={classes.header}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" height="60px">
          <IconButton size="small" className={classes.menuBtn} onClick={hanleMenu}>
            <MenuIcon />
          </IconButton>

          <Box component="div" mt="15px" mb="16px" ml="11px" fontSize="24px" fontWeight="400">
            <span className="helvetica pointer" onClick={() => forwardTo(`${hostConfig.adcloud}`)}>
              Palmax AdCloud
            </span>
          </Box>
        </Box>
        {userInfo ? <Logined userInfo={userInfo!} /> : window.location.hash === '#/login' ? '' : <NoLogin />}
      </Box>
    </header>
  );
};

const Logined: React.SFC<{ userInfo: IUserInfo }> = ({ userInfo }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [anchorEls, setAnchorEls] = React.useState<HTMLElement | null>(null);

  // 切换avatar下拉菜单
  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  // action前关闭菜单
  const closeMenuDecorator = (func: Function): (() => void) => {
    return () => {
      setAnchorEl(null);
      func();
    };
  };
  //切换setting下拉菜单
  const handlesettingClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEls(event.currentTarget);
  };
  //action前关闭caidan
  const closesettingMenuDecorator = (func: Function): (() => void) => {
    return () => {
      setAnchorEls(null);
      func();
    };
  };
  // 退出登录
  const logout = () => {
    userStore.logOut().then(() => {
      // router.history.push('/login');
      forwardTo(`${hostConfig.adcloud}/#/login`);
    });
  };

  return (
    <Box>
      <StyledBadge badgeContent={<i className="icon ion-md-alert" style={{ fontSize: 19, color: '#DE5145' }} />}>
        <i className="icon ion-md-notifications" style={{ fontSize: 21 }}></i>
      </StyledBadge>
      {/* 铃铛图标 */}
      <IconButton size="small" onClick={handlesettingClick} style={{ margin: '0px 25px' }}>
        <Settings style={{ width: 21, height: 21, color: '#000' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEls}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={Boolean(anchorEls)}
        onClose={() => setAnchorEls(null)}
      >
        <MenuItem>
          <span className="rubik-medium pl-size-16">Setting</span>
        </MenuItem>
        <Divider />
        <MenuItem
          style={{ width: 200 }}
          onClick={closesettingMenuDecorator(() => forwardTo(`${hostConfig.adcloud}/#/setting/twitter`))}
        >
          Twitter
        </MenuItem>
      </Menu>

      <IconButton size="small" onClick={handleAvatarClick}>
        <Box borderRadius="50%" width="32px" height="32px">
          <img className={classes.avatar} src={userInfo.avatar || contact.ios} alt="avatar" />
        </Box>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem>
          <div className="flex flex-acenter">
            <Box borderRadius="50%" width="32px" height="32px">
              <img className={classes.avatar} src={userInfo.avatar || contact.ios} alt="avatar" />
            </Box>
            <div className={classes.name_email}>
              <div className={classes.name} title={userInfo.fullName}>
                {userInfo.fullName}
              </div>
              <div className={classes.email} title={userInfo.email}>
                {userInfo.email}
              </div>
            </div>
          </div>
        </MenuItem>
        <Divider />
        <MenuItem onClick={closeMenuDecorator(() => forwardTo(`${hostConfig.adcloud}/#/account`))}>Profile</MenuItem>
        <MenuItem onClick={closeMenuDecorator(() => forwardTo(`${hostConfig.adcloud}/#/account/password`))}>
          Password
        </MenuItem>
        <MenuItem onClick={closeMenuDecorator(logout)}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

const useStylesNoLogin = makeStyles(() =>
  createStyles({
    signInLogin: {
      borderColor: '#4285F4',
      color: '#4285F4',
      transition: '0.25s ease',
      '&:hover': {
        backgroundColor: '#4285F4',
        color: '#fff'
      }
    }
  })
);

const NoLogin: React.SFC = () => {
  const classes = useStylesNoLogin();
  return (
    <Button
      className={classes.signInLogin}
      variant="outlined"
      onClick={() => {
        forwardTo(`${hostConfig.adcloud}/#/login`);
      }}
    >
      Sign in
    </Button>
  );
};
