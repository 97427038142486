import { action, observable, runInAction } from 'mobx';
import { userApi } from '../services/user.service';
import { IUserInfoDetail, IUserInfo } from 'interface/user.interface';
import { ITwAccount } from 'interface/twitter/compaign.interface';

export class UserStore {
  @observable
  public loading: boolean = false;

  @observable
  public userInfo: IUserInfo | null = null;

  @observable
  public userInfoDetail: IUserInfoDetail = {
    accountType: 'Company',
    fullName: '',
    email: '',
    avatar: '',
    company: '',
    phoneNumber: '',
    id: undefined
  };

  @observable
  public twitterAccouts: Array<ITwAccount> = [];

  @action.bound
  public setUserInfo(value: IUserInfo) {
    this.userInfo = value;
  }
  //退出登录
  @action
  public async logOut() {
    this.userInfo = null;
    await userApi.loginOut();
  }

  @action.bound
  public async getUserInfo() {
    const res = await userApi.getUserInfoByCookie();
    const detail: IUserInfoDetail = {
      accountType: res.account_type,
      fullName: res.name,
      email: res.email,
      avatar: res.user_avatar ? res.user_avatar : '',
      company: res.company ? res.company : '',
      phoneNumber: res.phone ? res.phone : '',
      id: res.id
    };

    runInAction(() => {
      this.userInfoDetail = detail;
      this.userInfo = detail as IUserInfo;
    });
    return res;
  }

  // 拉取关联的twitter帐户
  @action.bound
  public async getTwitterAccount() {
    return userApi.getTwitterAccounts().then(res => {
      runInAction(() => {
        res &&
          res.selectAccount &&
          (this.twitterAccouts = res.selectAccount.map((v: any) => ({ accountId: v, name: v })));
      });
      return this.twitterAccouts;
    });
  }

  @action.bound
  public async getTwPlatforms() {
    return userApi.getTwPlatforms().then(res => {
      return Promise.resolve(!!res.twitter);
    });
  }
}
export const userStore = new UserStore();
