import * as React from 'react';
import { Route } from 'react-router-dom';
import { ComponentExtends } from 'utils/extends';
import { withRouterProps } from 'components/HOC/WithRouterProps';
import { forwardTo } from 'utils/tool';
import { hostConfig } from 'config/app.config';

interface Props extends IClassName, IWithRouterProps {
  component: any;
  path?: string;
  exact?: boolean;
  render?: () => any;
  handlePermission: () => Promise<{ hasLogin: boolean; hasBindTwitter: boolean }>;
}

@withRouterProps
export class PrivateRoute extends ComponentExtends<Props> {
  public backLogin = () => {
    forwardTo(`${hostConfig.adcloud}/#/login`);
    // this.props.history!.push('/login');
  };

  public async componentDidMount() {
    const { handlePermission } = this.props;
    const r = await handlePermission();

    if (this.props.location.pathname === '/') {
      return;
    }

    if (!r.hasLogin) {
      this.backLogin();
      return;
    }

    if (!r.hasBindTwitter) {
      forwardTo(`${hostConfig.adcloud}/#/setting/twitter`);
    }
  }

  public render() {
    const { component: Component, ...rest } = this.props;
    return <Route {...rest} render={() => <Component />} />;
  }
}
