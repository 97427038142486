import { http } from "utils/http";
import {
  POST,
  GET,
  PUT,
  IHtttpOptions,
  HandlePanic,
  DELETE
} from "utils/decorators/http";
import { handleRequest } from "utils/tool";

class CardApi {
  /**
   *
   * @param params
   * @param option
   * @param handle
   */
  @PUT({
    url: "/api/user/password"
  })
  async putPassword<T = any>(
    params?: any,
    option?: IHtttpOptions,
    handle?: HandlePanic<T>
  ) {
    return await handleRequest<T>(params, handle);
  }

  // 获取所有 Ad Twwet
  @GET({
    url: "/api/creator_tw_api/twitter/tweets"
  })
  async getAdText(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 新建文案
  @POST({
    url: "/api/creator_tw_api/twitter/tweets"
  })
  async postAdText(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  //<twwet_id>
  @DELETE({
    url: "/api/creator_tw_api/twitter/tweets"
  })
  async delAdText(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 获取素材列表
  @GET({
    url: "/api/creator_tw_api/twitter/cards"
  })
  async getCreatives(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  //删除素材
  @DELETE({
    url: "/api/creator_tw_api/twitter/card"
  })
  async delCreative(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 上传文件
  @POST({
    url: "/api/upload"
  })
  async postUploadFile(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  @POST({
    url: "/api/creator_tw_api/twitter/card"
  })
  async postNewCard(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }
}

export const cardApi = new CardApi();
