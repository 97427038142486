import { IRouterTree, IRouter } from 'config/router/interface';
import { HandlePanic } from './decorators/http';
import { http } from './http';

/**
 * 多重继承
 *
 * @export
 * @param {*} derivedCtor
 * @param {any[]} baseCtors
 */
export function applyMixins(derivedCtor: any, baseCtors: any[]) {
  baseCtors.forEach(baseCtor => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
      derivedCtor.prototype[name] = baseCtor.prototype[name];
    });
  });
}

/**
 * 打印日志
 *
 * @param {string} msg
 * @param {string} [color='#fff']
 * @param {string} [background='#f44336']
 */
export function log(msg: string, color = '#fff', background = '#f44336') {
  console.log(`%c ${msg}`, `color:${color};background:${background}`);
}

/**
 * menu数组转tree数组
 *
 * @export
 * @param {IRouter[]} menu
 * @param {number} [len=1]
 * @param {IRouterTree[]} [r=[]]
 * @returns {IRouterTree[]}
 */
export function handleTree<T>(menu: IRouter<T>[], len: number = 1, r: IRouterTree<T>[] = []): IRouterTree<T>[] {
  const children: IRouter<T>[] = [];
  const o: IRouter<T>[] = [];

  menu.forEach(i => {
    if (i.parentKey) {
      if (i.parentKey.length === len) {
        children.push({ ...i });
      } else {
        o.push({ ...i });
      }
    } else {
      r.push({ ...i });
    }
  });

  r.forEach(j => {
    handleChildTree<T>(j, children);
  });

  if (o.length > 0) {
    handleTree(o, len + 1, r);
  }

  return r;
}

/**
 * 递归 ChilTree
 *
 * @param {IRouterTree} node
 * @param {IRouter[]} children
 */
function handleChildTree<T>(node: IRouterTree<T>, children: IRouter<T>[]) {
  if (node.children) {
    node.children.forEach(i => {
      handleChildTree(i, children);
    });
  } else {
    node.children = [];
    children.forEach(i => {
      if (i.parentKey === node.key) {
        node.children!.push(i);
      }
    });
  }
}

export async function handleRequest<T>(params?: any, handle?: HandlePanic<T>) {
  const res = await http.request<T>(params);
  if (handle) {
    const r = handle(res.data);
    return Promise.resolve(r);
  }
  return Promise.resolve(res.data);
}

export function isImage(file: any) {
  const fileName = file.name || file.path;
  const suffix = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
  if (suffix === 'jpg' || suffix === 'jpeg' || suffix === 'bmp' || suffix === 'png') {
    return true;
  } else {
    return false;
  }
}

export function convertBytesToMbsOrKbs(filesize: number) {
  var size = '';
  // I know, not technically correct...
  if (filesize >= 1000000) {
    size = filesize / 1000000 + 'M';
  } else if (filesize >= 1000) {
    size = filesize / 1000 + 'K';
  } else {
    size = filesize + 'bytes';
  }
  return size;
}

export async function createFileFromUrl(url: string) {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = { type: data.type };
  const filename = url
    .replace(/\?.+/, '')
    .split('/')
    .pop();
  const ext = data.type.split('/').pop();
  return new File([data], `${filename}.${ext}`, metadata);
}

/**
 * @description 外部链接跳转
 * @param link
 */
export function forwardTo(link: string, isOther: boolean = false): void {
  let alink: HTMLAnchorElement | null = document.createElement('a');
  alink.href = link;
  alink.target = isOther ? '_blank' : '_self';
  alink.click();
  alink = null;
}
