import * as React from 'react';
import { userApi } from 'services/user.service';
import { applyMixins } from 'utils/tool';

export class ApiExtends {
  readonly userApi$$ = userApi;
}

// export class ComponentExtends<T = {}, S = {}, SS = any> extends React.Component<T, S, SS> {
//   readonly $message = message;
//   readonly userApi$$ = userApi;
//   readonly messageApi$$ = messageApi;
// }
export class StoreExtends extends ApiExtends {}

export class ComponentExtends<T = {}, S = {}, SS = any> extends React.Component<T, S, SS>
  implements ApiExtends, React.Component<T, S, SS> {
  readonly userApi$$ = userApi;
}

applyMixins(ComponentExtends, [ApiExtends]);
