import loadable from 'react-loadable';
import { Loading } from 'components/common/Loading';

const handleloadableComponent = (component: () => Promise<any>) =>
  loadable({
    loader: component,
    loading: Loading
  });

export const routerLoadableComponents = {
  Setting: () => null,
  Test: handleloadableComponent(() => import(/* webpackChunkName: "Test" */ '../../pages/Test')),
  Twitter: handleloadableComponent(() => import(/* webpackChunkName: "Twitter" */ '../../pages/Twitter/router'))
};

export type RouterLoadableComponentsTypeKeys = keyof typeof routerLoadableComponents;
