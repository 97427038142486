import React from 'react';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { HEADER_HEIGHT } from 'constants/styles';
import { useRouter } from 'components/HOC/CustomRouter';
import { IRouter } from 'config/router/interface';
import { Menu } from 'components/common/Menu';
import { BLACK_FONT_COLOR } from 'constants/styles';
import { forwardTo } from 'utils/tool';
import { hostConfig } from 'config/app.config';

const useStyles = makeStyles({
  drawerPaper: {
    width: '290px',
    paddingTop: HEADER_HEIGHT,
    borderRight: 0,
    boxShadow: '1px 0 4px rgba(0, 0, 0, 0.2)'
  },
  bottomLinks: {
    height: '103px',
    paddingTop: '30px',
    borderTop: '1px solid E6E6E6',
    boxShadow: '0 -1px 2px rgba(0, 0, 0, 0.05)',
    position: 'absolute',
    bottom: 0,
    left: 0,
    fontSize: '14px',
    color: BLACK_FONT_COLOR,
    width: '100%'
  }
});

interface Props {
  isOpen: boolean;
  closeMenu: () => void;
  menus: IRouter[];
  selectedItemKey?: string;
}

export const LayoutSidebar = ({ isOpen, closeMenu, menus, selectedItemKey }: Props) => {
  const classes = useStyles();
  const router = useRouter();

  const handleBottomClick = (path: string) => {
    router.history.push(path);
    closeMenu();
  };

  React.useEffect(() => {
    // 外部点击隐藏导航栏
    const clickAwayListener = (ev: Event) => {
      const parentNodeRef = document.getElementById('layoutSidebar_ref');
      const hasParent = (child: HTMLElement, parent: HTMLElement) => {
        while (child) {
          if (child === parent) {
            return true;
          }
          child = child.parentNode as HTMLElement;
        }
        return false;
      };
      if (!hasParent(ev.target as HTMLElement, parentNodeRef!)) {
        closeMenu();
      }
    };

    document.addEventListener('click', clickAwayListener);
    return () => {
      document.removeEventListener('click', clickAwayListener);
    };
  }, [closeMenu]);

  return (
    <Drawer
      id="layoutSidebar_ref"
      classes={{
        paper: classes.drawerPaper
      }}
      anchor="left"
      open={isOpen}
      onClose={closeMenu}
      variant="persistent"
    >
      <Menu data={menus} isTitle={true} selectedItemKey={selectedItemKey} onClick={closeMenu} />
      <div className={classes.bottomLinks}>
        <div className="rubik-regular pl-size-14">
          <span onClick={() => handleBottomClick('/')} className="pl-mgl-19 pl-mgr-20 pl-link">
            About
          </span>
          <span onClick={() => forwardTo(`${hostConfig.adcloud}/#/privacy`, true)} className="pl-mgr-20 pl-link">
            Privacy
          </span>
          <span onClick={() => handleBottomClick('/')} className="pl-link">
            Terms
          </span>
        </div>
        <div className="pl-mgl-16 pl-mgt-15">©2019 Palmax Limited</div>
      </div>
    </Drawer>
  );
};
