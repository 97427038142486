import { POST, GET, IHtttpOptions, HandlePanic } from 'utils/decorators/http';
import { handleRequest } from 'utils/tool';
import { http } from 'utils/http';

class AddCampaignApi {
  /** 
  获取部分下拉数据
  Request
  - URL: api/creator_tw_api/twitter/data/<data_type>
  - Method: GET
  - Request Route
    countries: 国家
    interests: 兴趣
    os_version: 系统版本
    platform: 平台
    language: 语言
    device_model: 设备
    carriers: 运营商
    follower_look_alikes: 粉丝（暂未实现）
  */
  @GET({
    url: '/api/creator_tw_api/twitter/data'
  })
  async getSelect<T = any>(params?: any, option?: IHtttpOptions, handle?: HandlePanic<T>) {
    return await handleRequest<T>(params, handle);
  }

  /**
  Request：获取关联的应用
  - URL: api/creator_tw_api/twitter/data/<account_id>/conversion_apps
      account_id: 账户id
  */
  async getLineItemApps<T = any>(account_id: string, handle?: HandlePanic<T>) {
    return await http
      .get(`/api/creator_tw_api/twitter/data/${account_id}/conversion_apps`)
      .then(res => Promise.resolve(handle ? handle(res.data) : res.data));
  }
  /**
  Request：获取内容分类
  - URL: api/creator_tw_api/twitter/data/<account_id>/content_categories
      account_id: 账户id
  */
  @GET({
    url: '/api/creator_tw_api/twitter/data/content_categories'
  })
  async getCategories<T = any>(params?: any, option?: IHtttpOptions, handle?: HandlePanic<T>) {
    return await handleRequest<T>(params, handle);
  }

  /**
  Request：follower_look_alikes
     querystring: 'John'
  */
  @GET({
    url: '/api/creator_tw_api/twitter/data/follower_look_alikes/data'
  })
  async getFollower_look_alikesList<T = any>(params?: any, option?: IHtttpOptions, handle?: HandlePanic<T>) {
    return await handleRequest<T>(params, handle);
  }

  @GET({
    url: '/api/creator_tw_api/twitter/campaigns'
  })
  async getCampaing<T = any>(params?: any, option?: IHtttpOptions, handle?: HandlePanic<T>) {
    return await handleRequest<T>(params, handle);
  }

  @POST({
    url: '/api/creator_tw_api/twitter/campaigns'
  })
  async submit<T = any>(params?: any, option?: IHtttpOptions, handle?: HandlePanic<T>) {
    return await handleRequest<T>(params, handle);
  }

  async update<T = any>(campaignId: number, data: any, handle?: HandlePanic<T>) {
    return await http
      .put(`/api/creator_tw_api/twitter/campaigns/${campaignId}`, data)
      .then(res => Promise.resolve(handle ? handle(res.data) : res.data));
  }
}

export const addCampaignApi = new AddCampaignApi();
