import React from 'react';
import './styles/index.scss';
import { store } from './store';
import { configure } from 'mobx';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
// import { hot } from 'react-hot-loader/root';
import { Router as App } from './pages/router';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

configure({ enforceActions: 'always' });

if (process.env.NODE_ENV === 'production') {
  console.log = function() {};
}

// cover base styles of material UI
const theme = createMuiTheme({
  palette: { primary: { main: '#4285F4' } },
  typography: { fontFamily: 'Rubik Regular, Helvetica, Arial, sans-serif' }
});

const render = (Component: React.ComponentClass<any>) => {
  ReactDOM.render(
    <Provider {...store}>
      <ThemeProvider theme={theme}>
        <Component />
      </ThemeProvider>
    </Provider>,
    document.getElementById('root') as HTMLElement
  );
};
// render(hot(App));
render(App);
// render
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
