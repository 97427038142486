import { observable, action, IReactionDisposer } from 'mobx';
import { Location } from 'history';
import { IRouter } from 'config/router/interface';
import { RouterLoadableComponentsTypeKeys } from 'config/router/loadable.config';
import { routers } from 'config/router/router.config';

export class RouterStore {
  @observable
  currentRouter: Location = {} as Location;

  @observable
  currentModule: IRouter = {} as IRouter;

  @observable
  currentRoute: IRouter = {} as IRouter;

  routerDisposer: IReactionDisposer;

  @action.bound
  updateCurrentRouter(router: Location) {
    this.currentRouter = router;
  }

  @action.bound
  updateCurrentModule(cur: RouterLoadableComponentsTypeKeys) {
    const r = routers.routers.find(i => i.component === cur);
    if (r) {
      this.currentModule = r;
    }
  }

  @action.bound
  updateCurrentRoute(route: IRouter) {
    this.currentRoute = route;
  }
}

export const routerStore = new RouterStore();
