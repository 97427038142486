import React from 'react';
import { Icon } from '../Icon';
import loadingIcon from 'assets/icons/loading.svg';
import './index.css';

export const Loading = () => (
  <div className="loader">
    <div className="loader-inner ball-scale-multiple">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export const LoadingIcon = (props: { show?: boolean }) => (
  <span style={{ display: props.show ? 'flex' : 'none' }}>
    <Icon src={loadingIcon} className="loading-icon"></Icon>
  </span>
);

export const LoadingBallBeat = (props: { show?: boolean; active?: boolean }) => (
  <div
    className={'loader ' + (props.active ? 'active-ball-beat' : '')}
    style={{ display: props.show ? 'flex' : 'none' }}
  >
    <div className="loader-inner ball-beat">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

Loading.LoadingIcon = LoadingIcon;
Loading.LoadingBallBeat = LoadingBallBeat;
