import React from 'react';
import { Icon, MenuList, MenuItem, makeStyles } from '@material-ui/core';
import { Icon as CustomIcon } from '../Icon';
import { IRouter } from 'config/router/interface';
import { useRouter } from 'components/HOC/CustomRouter';
import c from 'clsx';
import { FONT_ICON, BLACK_FONT_COLOR } from 'constants/styles';

const MenuItemHeight = '40px';
const useStyles = makeStyles({
  menuItem: {
    alignItems: 'flex-start',
    fontSize: '15px',
    lineHeight: '18px',
    minHeight: MenuItemHeight,
    padding: '10px 32px 10px',
    '&.Mui-selected:hover': {
      background: '#ECF1FA'
    },
    '&.Mui-selected': {
      background: '#ECF1FA'
    },
    color: BLACK_FONT_COLOR
  },
  ...FONT_ICON,
  sideBarTop: {
    paddingTop: '12px'
  },
  menuItemText: {
    width: '123px',
    minWidth: '123px',
    wordBreak: 'break-word',
    overflow: 'hidden',
    fontSize: 14,
    fontFamily: 'Rubik Regular',
    color: '#000',
    whiteSpace: 'pre-wrap',
    lineHeight: '18px'
  }
});

interface Props {
  data: IRouter[];
  isTitle: boolean;
  selectedItemKey?: string;
  onClick?: () => void;
}

export const Menu = ({ data, isTitle, selectedItemKey, onClick }: Props) => {
  const classes = useStyles();
  const router = useRouter();

  // icon名称默认ion-md;
  // 如果不要默认用全称比如ion-logo-facebook
  const getIcon = (iconStr: string) => {
    if (iconStr.indexOf('ion-') === 0) {
      return iconStr;
    }
    return `ion-md-${iconStr}`;
  };

  // icon可以直接src引用的一个图片地址
  // 也可以是通过class传入,通过字体文件生成图标
  const renderIcon = (iconStr: string | undefined) => {
    return iconStr && iconStr.includes('/static') ? (
      <CustomIcon src={iconStr}></CustomIcon>
    ) : (
      <Icon className={c('icon', getIcon(iconStr!), classes.fontIcon)} classes={{ root: classes.fontIconRoot }} />
    );
  };

  const handleClick = (path: string | undefined) => {
    if (!path) {
      return;
    }
    if (path.includes('http')) {
      window.location.href = path;
      return;
    }
    router.history.push(path);
    onClick && onClick();
  };

  return (
    <MenuList className={classes.sideBarTop}>
      {data.map((i, idx) =>
        i.noNav || i.outside ? null : (
          <MenuItem
            key={idx}
            className={classes.menuItem}
            onClick={() => handleClick(i.path)}
            selected={i.key === selectedItemKey}
          >
            {renderIcon(i.icon)}
            <span className={classes.menuItemText}>{isTitle && i.title}</span>
          </MenuItem>
        )
      )}
    </MenuList>
  );
};
