import { IRouterStut } from './interface';
import { RouterLoadableComponentsTypeKeys } from './loadable.config';
import { hostConfig } from '../app.config';

export const routers: IRouterStut<RouterLoadableComponentsTypeKeys> = {
  routers: [
    {
      key: '1',
      title: 'Marketplace',
      icon: 'apps',
      path: hostConfig.adcloud
    },
    {
      key: '2',
      title: 'Setting',
      icon: 'settings',
      path: `${hostConfig.adcloud}/#/setting/twitter`
    },
    {
      key: '3',
      title: 'Creator',
      icon: 'paper-plane',
      path: '/twitter',
      component: 'Twitter',
      noNav: true
    },
    {
      key: '4',
      title: 'test',
      icon: 'test',
      path: '/testdemovoid',
      component: 'Test',
      noNav: true
    }
  ]
};
