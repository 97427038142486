import { userStore } from './user.store';
import { routerStore } from './router.store';
import { cardStore } from './twitter/card.store';
import { addCampaignStore } from './twitter/addCampaign.store';
import { campaignStore } from './twitter/campaign.store';


export const store = {
  userStore,
  routerStore,
  cardStore,
  campaignStore,
  addCampaignStore
};

export default store;

export type IStore = typeof store;
