import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  img: {
    width: '100%',
    height: '100%'
  }
});

interface Props extends IClassName {
  src: string;
  size?: number;
  ml?: string;
  mr?: string;
}

export const Icon = ({ src, size, className, ml, mr }: Props) => {
  const classes = useStyles();
  const s = size ? `${size}px` : '14px';

  return (
    <Box component="i" height={s} width={s} display="inline-block" lineHeight={s} className={className} ml={ml} mr={mr}>
      <img className={classes.img} src={src} alt="" />
    </Box>
  );
};
