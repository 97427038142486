import { IKV } from 'interface';
import { AxiosRequestConfig, Method } from 'axios';

export interface IHtttpOptions {
  url?: string;
  headers?: IKV;
  timeout?: number;
  data?: any;
  params?: any;
  responseType?: string;
}

export type HandlePanic<T> = (data: T) => T;

const buildDecorator = (method: Method, options: IHtttpOptions): MethodDecorator => (
  target,
  propertyKey,
  descriptor: TypedPropertyDescriptor<any>
) => {
  const oldVal = descriptor.value;
  descriptor.value = (...args: [any, IKV]) => {
    const [params, newOption] = args;
    const axiosOpt: AxiosRequestConfig = {
      method
    };
    Object.assign(axiosOpt, options);

    if (typeof params !== 'string') {
      if (method === 'get') {
        Object.assign(axiosOpt, { params });
      } else {
        Object.assign(axiosOpt, { data: params });
      }
    } else {
      axiosOpt.url = `${axiosOpt.url}/${params}`;
    }

    if (newOption) {
      Object.assign(axiosOpt, newOption);
    }

    return oldVal.apply(target, [axiosOpt, target]);
  };
};

/**
 * @GET({
 *  url:'/user'
 *  header:{}
 * })
 * @param options
 */
export function GET(options: IHtttpOptions) {
  const method = 'get';
  return buildDecorator(method, options);
}

/**
 * @POST({
 *  url:'/user'
 *  header:{}
 * })
 * @param options
 */
export function POST(options: IHtttpOptions) {
  const method = 'post';
  return buildDecorator(method, options);
}

/**
 * @PUT({
 *  url:'/user'
 *  header:{}
 * })
 * @param options
 */
export function PUT(options: IHtttpOptions) {
  const method = 'put';
  return buildDecorator(method, options);
}

/**
 * @DELETE({
 *  url:'/user'
 *  header:{}
 * })
 * @param options
 */
export function DELETE(options: IHtttpOptions) {
  const method = 'delete';
  return buildDecorator(method, options);
}
