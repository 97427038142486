import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import loadable from 'react-loadable';
import { PrivateRoute } from 'components/HOC/PrivateRoute';
import { BasicLayout } from 'components/layout/BasicLayout';
import { routers } from 'config/router/router.config';
import { routerLoadableComponents, RouterLoadableComponentsTypeKeys } from 'config/router/loadable.config';
import { IRouterComponent } from 'interface';
import { CustomHashRouter } from 'components/HOC/CustomRouter';
import { inject, observer } from 'mobx-react';
import { IStore } from 'store';
import { UserStore } from 'store/user.store';

interface Props {
  userStore?: UserStore;
}

@inject((store: IStore) => {
  const { userStore } = store;
  return { userStore };
})
@observer
export class Router extends React.Component<Props> implements IRouterComponent<RouterLoadableComponentsTypeKeys> {
  readonly router = routers;
  readonly routerComponents = routerLoadableComponents;

  public Login = loadable({
    loader: () => import(/* webpackChunkName: "login" */ 'pages/Login'),
    loading: () => null
  });

  public Privacy = loadable({
    loader: () => import(/* webpackChunkName: "privacy" */ 'pages/Privacy'),
    loading: () => null
  });

  public handleLoginPermission = async () => {
    const { getUserInfo, getTwPlatforms } = this.props.userStore!;
    const res = await getUserInfo();
    const hasBindTwitter = await getTwPlatforms();
    return {
      hasLogin: !!res,
      hasBindTwitter: !!hasBindTwitter
    };
  };

  public routerRenderer = () =>
    this.router.routers.map(i =>
      i.path && i.component ? (
        <Route key={i.key} exact={i.exact} path={i.path} component={this.routerComponents[i.component]} />
      ) : null
    );

  public redirectRenderer = () => <Route path="/" exact render={() => <Redirect to="/twitter" />} />;

  public render() {
    const { userInfo } = this.props.userStore!;

    return (
      <CustomHashRouter>
        <BasicLayout menus={this.router.routers} userInfo={userInfo}>
          <Switch>
            <Route exact path="/login" component={this.Login} />
            <Route exact path="/privacy" component={this.Privacy} />
            {this.redirectRenderer()}
            <Route
              path="/"
              render={() => (
                <PrivateRoute component={this.routerRenderer} handlePermission={this.handleLoginPermission} />
              )}
            />
          </Switch>
        </BasicLayout>
      </CustomHashRouter>
    );
  }
}
